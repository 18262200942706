import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";

const LinkInput = ({
  links,
  setLinks,
  baseurl,
  brand,
  activity,
  loadLinks,
  isValidURL,
}) => {
  const [helpLink, setHelpLink] = useState(null);
  const [currentInput, setCurrentInput] = useState("");
  const [showSaveButton, setShowSaveButton] = useState(false);

  useEffect(() => {
    const unclickedLink = links.find(
      (link) => link.clicked === 0 && link.url !== "" && isValidURL(link.url)
    );
    setHelpLink(unclickedLink || null);
  }, [links, isValidURL]);

  const handleInputChange = (value) => {
    setCurrentInput(value);
    setShowSaveButton(isValidURL(value));
  };

  const saveLink = async (newLink, newIndex) => {
    try {
      // 儲存前再次確認最新資料庫狀態
      await loadLinks();
      await axios.post(`${baseurl}/save-link`, {
        brand,
        activity,
        data: { [newIndex]: currentInput },
      });

      if (helpLink) {
        window.open(helpLink.url, "_blank");
        await updateLinkClickCount(helpLink.index);
      }

      setLinks([...links, { ...newLink, index: newIndex }]);
      setCurrentInput("");
      setShowSaveButton(false);
      loadLinks(); // 確保連結更新後顯示最新的狀態
    } catch (error) {
      console.error("儲存連結時發生錯誤:", error);
    }
  };

  const handleSaveAndRedirect = async () => {
    const newLink = { url: currentInput, clicked: 0 };
    const newIndex = Math.max(...links.map((link) => link.index), 0);

    await loadLinks(); // 儲存前重新載入最新連結
    // 檢查是否有待被點擊的連結 (helpLink)
    if (!helpLink) {
      // 沒有待點擊連結，直接儲存
      saveLink(newLink, newIndex);
    } else {
      // 有待點擊的連結，顯示跳轉確認框
      Swal.fire({
        title: `正要跳轉到以下連結:\n${helpLink.url}`,
        html: `請確認網址正確再點擊前往<br>成功幫助別人點擊連結才會儲存成功哦><`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "前往並儲存您的連結",
        confirmButtonColor: "#e69b4c",
        cancelButtonText: "我再想想",
        cancelButtonColor: "gray",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "儲存成功!",
            text: "您的連結準備要被點囉 (｢･ω･)｢",
            icon: "success",
            confirmButtonColor: "#e69b4c",
            confirmButtonText: "好耶！",
          });
          // 使用者確認後，跳轉並儲存連結
          saveLink(newLink, newIndex);
        }
      });
    }
  };

  const updateLinkClickCount = async (index) => {
    try {
      await axios.post(`${baseurl}/update-link`, {
        brand,
        activity,
        index,
      });

      setLinks(
        links.map((link) =>
          link.index === index ? { ...link, clicked: link.clicked + 1 } : link
        )
      );
    } catch (error) {
      console.error("更新點擊數時發生錯誤:", error);
    }
  };

  const handlePaste = () => {
    navigator.clipboard
      .readText()
      .then((text) => {
        const cleanedUrl = extractValidURL(text);
        handleInputChange(cleanedUrl);
      })
      .catch((err) => {
        console.error("無法讀取剪貼簿內容: ", err);
      });
  };

  const extractValidURL = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const matches = text.match(urlRegex);
    return matches && matches[0] ? matches[0] : "";
  };

  return (
    <div className="input-row">
      <div className="hint">
        <p>儲存連結時將會自動跳轉至他人連結，若為第一位使用者將直接儲存!</p>
      </div>
      <input
        type="url"
        value={currentInput}
        placeholder="貼上您的分享連結"
        onChange={(e) => handleInputChange(e.target.value)}
      />
      <img
        src="/paste.svg"
        alt="Paste"
        className="paste-icon"
        onClick={handlePaste}
        style={{ cursor: "pointer" }}
      />
      {showSaveButton && (
        <span className="save-icon-wrapper" onClick={handleSaveAndRedirect}>
          <img src="/square-ok.svg" alt="Save" className="save-icon" />
        </span>
      )}
      {!showSaveButton && currentInput !== "" && (
        <div className="error-hint">請輸入正確網域的連結！</div>
      )}
    </div>
  );
};

export default LinkInput;
