import React, { useState, useEffect } from "react";
import { Tooltip } from "react-tooltip";

const LinkStatus = ({ links, showStatusLinks, toggleLinks }) => {
  const [localLinks, setLocalLinks] = useState([]);

  useEffect(() => {
    // 從 localStorage 讀取連結
    const storedLinks = JSON.parse(localStorage.getItem("userLinks") || "[]");
    setLocalLinks(storedLinks);

    // 更新 localStorage
    const updatedLinks = links.map((link) => {
      const storedLink = storedLinks.find((sl) => sl.url === link.url);
      return {
        ...link,
        localClicked: storedLink ? storedLink.localClicked : false,
      };
    });
    localStorage.setItem("userLinks", JSON.stringify(updatedLinks));
    setLocalLinks(updatedLinks);
  }, [links]);

  const handleLinkClick = (url) => {
    const updatedLinks = localLinks.map((link) =>
      link.url === url ? { ...link, localClicked: true } : link
    );
    localStorage.setItem("userLinks", JSON.stringify(updatedLinks));
    setLocalLinks(updatedLinks);
  };

  return (
    <div>
      <div className="link-status-fold">
        <span className="status-fold-button" onClick={toggleLinks}>
          {showStatusLinks
            ? "▲ 隱藏我的連結"
            : "▼ 顯示我貼上的連結 (只顯示最新五筆)"}
        </span>
      </div>
      <div
        className={`link-history ${showStatusLinks ? "expanded" : "collapsed"}`}
      >
        {localLinks
          .slice(0, -1)
          .slice(-5)
          .map((link, index) => (
            <div
              key={index}
              className="link-status-item"
              onClick={() => handleLinkClick(link.url)}
            >
              <span
                className={`link-status ${
                  link.clicked ? "clicked" : "unclick"
                }`}
              >
                {link.clicked > 0 || link.localClicked
                  ? "● 已點擊"
                  : "● 待點擊"}
              </span>
              <span
                data-tooltip-id="link-tooltip"
                data-tooltip-content={link.url}
              >
                {link.url}
              </span>
            </div>
          ))}
      </div>
      <Tooltip id="link-tooltip" className="linktooltip" />
    </div>
  );
};

export default LinkStatus;
