import React from "react";
import { Tooltip } from "react-tooltip";

const LinkHistory = ({ links, showAllLinks, toggleLinks }) => {
  return (
    <div>
      <div className="link-history-fold">
        <span className="history-fold-button" onClick={toggleLinks}>
          {showAllLinks ? "▲ 隱藏歷史連結 (只顯示最新十筆)" : "▼ 顯示歷史連結"}
        </span>
      </div>
      <div
        className={`link-history ${showAllLinks ? "expanded" : "collapsed"}`}
      >
        {links
          .slice(0, -1)
          .slice(-10)
          .map((link, index) => {
            let color = `rgb(64, 64, 64)`; // 默認為深灰色
            let opacity = 1; // 默認不透明

            if (index < 3) {
              color = `rgb(148, 148, 148)`; // 前三筆顯示淺灰色
              opacity = 0.5 - 0.15 * -index; // 漸變淡出效果(倒敘)
            } else if (index === 3) {
              color = `rgb(128, 128, 128)`; // 第四筆過渡到灰色
            } else if (index === 4) {
              color = `rgb(98, 98, 98)`; // 第五筆過渡到接近深灰色
            }

            return (
              <div
                key={link.index}
                // className="input-row"
                className="link-history-item"
                style={{
                  color,
                  opacity,
                  transition: "color 0.5s, opacity 0.5s",
                }}
              >
                <span style={{ fontSize: 18, marginLeft: 10, marginRight: 10 }}>
                  {link.index}.{" "}
                </span>
                <span
                  data-tooltip-id="link-tooltip"
                  data-tooltip-content={link.url}
                >
                  {link.url}
                </span>
              </div>
            );
          })}
        <Tooltip id="link-tooltip" className="linktooltip" />
      </div>
    </div>
  );
};

export default LinkHistory;
