import React, { useState, useEffect } from "react";
import axios from "axios";
import LinkInput from "./LinkInput";
import LinkStatus from "./LinkStatus";
import LinkHistory from "./LinkHistory";

const baseurl = "/api";

function Activity({ brand, activity, isActive }) {
  const [links, setLinks] = useState([
    { index: 1, url: "", clicked: 0, error: false },
  ]);
  const [linksLoaded, setLinksLoaded] = useState(false);
  const [canPaste, setCanPaste] = useState(true);
  const [showStatusLinks, setShowStatusLinks] = useState(false);
  const [showAllLinks, setShowAllLinks] = useState(false);

  useEffect(() => {
    if (isActive) {
      loadLinks();
    } else {
      resetState();
    }
  }, [isActive, brand, activity]);

  useEffect(() => {
    if (linksLoaded) {
      const lastLink = links[links.length - 1];
      if (lastLink && lastLink.url.trim() !== "") {
        addLinkField();
      }
      setLinksLoaded(false);
    }
  }, [links, linksLoaded]);

  const loadLinks = () => {
    axios
      .get(`${baseurl}/load-links`, { params: { brand, activity } })
      .then((response) => {
        const data = response.data;
        const loadedLinks = Object.keys(data).map((key) => ({
          index: parseInt(key),
          url: data[key].url,
          clicked: data[key].clicked,
          error: false,
        }));
        setLinks(
          loadedLinks.length > 0
            ? loadedLinks
            : [{ index: 1, url: "", clicked: 0, error: false }]
        );
        setLinksLoaded(true);
        updateCanPasteAndInitialLink(loadedLinks);
        // checkUserLinkClicked(loadedLinks);
      })
      .catch((error) => {
        console.error("載入連結時發生錯誤:", error);
      });
  };

  const resetState = () => {
    setLinks([{ index: 1, url: "", clicked: 0, error: false }]);
    setLinksLoaded(false);
    setCanPaste(true);
  };

  const updateCanPasteAndInitialLink = (links) => {
    const unclickedLink = links.find(
      (link) => link.clicked === 0 && link.url !== ""
    );
    setCanPaste(!unclickedLink);
  };

  const addLinkField = () => {
    const newIndex = Math.max(...links.map((link) => link.index)) + 1;
    setLinks([
      ...links,
      { index: newIndex, url: "", clicked: 0, error: false },
    ]);
  };

  const isValidURL = (string) => {
    const urlRegex = new RegExp(
      // eslint-disable-next-line
      `^(https:\/\/(shp\\.ee|www\\.momoshop\\.com\\.tw)\/)`
    );
    const urlMatch = string.match(urlRegex);
    if (brand === "Shopee") {
      return urlMatch && urlMatch[0] === "https://shp.ee/";
    } else if (brand === "Momo") {
      return urlMatch && urlMatch[0] === "https://www.momoshop.com.tw/";
    } else {
      return false;
    }
  };

  return isActive ? (
    <div className="activity">
      <LinkInput
        links={links}
        setLinks={setLinks}
        baseurl={baseurl}
        canPaste={canPaste}
        brand={brand}
        activity={activity}
        loadLinks={loadLinks}
        isValidURL={isValidURL}
      />
      <LinkStatus
        links={links}
        showStatusLinks={showStatusLinks}
        toggleLinks={() => setShowStatusLinks(!showStatusLinks)}
      />
      <LinkHistory
        links={links}
        showAllLinks={showAllLinks}
        toggleLinks={() => setShowAllLinks(!showAllLinks)}
      />
    </div>
  ) : null;
}

export default Activity;
